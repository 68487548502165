import React, { useState } from 'react';
import Modal from '../../Utilities/Modal';
import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import { verifyOtp } from '../../actions/authAction';
import { toast } from 'react-toastify';
import { navigate } from 'gatsby';

const VerifyOtpModal = ({ onClose, sessionId, phone }) => {
  const [otp, setOtp] = useState('');
  const [isVerifying, setVerifying] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('sessionId ', sessionId, 'OTP', otp, 'Phone ', phone);

    try {
      setVerifying(true);
      let otp_data = { otp: otp, session_id: sessionId, mobile: phone };
      let result = await verifyOtp(otp_data);
      console.log('result from verifyOtp ', result);
      setVerifying(false);
      if (result?.status) {
        navigate('/complaints');
        onClose();
      } else {
        toast.error('Wrong OTP');
      }
    } catch (error) {
      console.log('Error in verifyOtp: ', error);
      toast.error('Error in getting info from server');
      return null;
      // throw error;
    }
  };

  return (
    <Modal toggle={onClose}>
      <Card>
        <CardBody>
          <h3>Please Enter OTP </h3>
          <form onSubmit={handleSubmit}>
            <InputGroup fullWidth>
              <input type="text" placeholder="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
            </InputGroup>
            <Button type="submit" status="Success" shape="SemiRound" fullWidth>
              {isVerifying ? 'Verifying...' : 'Verify'}
            </Button>
          </form>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default VerifyOtpModal;
